<template>
  <component :is="component" class="relative group flex flex-col cursor-pointer" :to="href" @click="onClick">
    <div class="w-full aspect-4/3 bg-sky-blue-500 overflow-hidden rounded-t-lg relative">
      <img
        v-if="imageUrl"
        v-lazy="imageUrl"
        :alt="imageAlt"
        class="w-full aspect-4/3 transition-quick object-cover group-focus:scale-110 group-hover:scale-110" />
      <div
        class="absolute pl-4 pt-3 top-0 left-0 text-left text-white font-light w-full h-full pointer-events-none text-sm lg:text-base"
        :class="type === 'video' ? 'bg-scrim-full' : 'bg-scrim-sm'">
        {{ typeText }}
      </div>
      <div
        v-if="type === 'video'"
        class="absolute inset-0 text-white font-light w-full h-full pointer-events-none flex items-center justify-center">
        <div class="p-1 border-2 rounded-full border-white">
          <HoapIcon icon="Play" class="w-6 lg:w-8 h-6 lg:h-8" />
        </div>
      </div>
    </div>
    <div class="bg-white w-full flex-1 rounded-b-lg flex items-center">
      <div class="w-3/4 mx-auto text-center py-2 lg:py-4 font-medium leading-tight text-black text-xs lg:text-sm">
        {{ props.title }}
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  import { HoapIcon } from '..'
  import { useLightbox } from '~/composables/useLightbox'

  const props = defineProps({
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    imageAlt: {
      type: String,
      default: '',
    },
    slug: String,
    vimeoVideoId: String,
    description: String,
    videoType: String,
  })

  const route = useRoute()
  const { showVideo } = useLightbox()

  const typeText = computed(() => {
    switch (props.type) {
      case 'video':
        return 'Watch'
      case 'blog':
        return 'Read'
    }

    return ''
  })

  const component = computed(() => {
    if (!!props.slug || (props.type === 'video' && props.vimeoVideoId && route.path !== '/library'))
      return resolveComponent('NuxtLink')

    return 'button'
  })

  const href = computed(() => (props.slug ? `/library/${props.slug}` : `/library?video-id=${props.vimeoVideoId}`))

  const onClick = () => {
    if (props.type === 'video' && props.vimeoVideoId && route.path === '/library') {
      showVideo({
        id: props.vimeoVideoId,
        title: props.title,
        description: props.description,
        type: props.videoType,
      })
    }
  }
</script>
